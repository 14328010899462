<template>
    <div>
        <div class="section-title">
            <h3>Detalle del Lote {{id}}</h3>
        </div>
        <div class="content" >
            <p>{{data.type === 1 ? 'Entrada': 'Salida'}}</p>
            <p><strong>Sucursal: </strong>{{data.branch_office}}</p>
        </div>
        <div class="content">
            <div class="activity-items">
                <b-table
                    :data="data.samples"
                    striped
                    :columns="columns"
                    :loading="loading"
                ></b-table>
            </div>
        </div>
        <div class="content">
            <router-link class=""
                         :to="{name:'io-samples'}">
                Regresar
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Show",
    props: {
        id: Number | String
    },
    data() {
        return {
            sample: '',
            loading: false,
            data: [],
            columns: [
                {
                    field: 'code',
                    label: 'Código',
                    searchable: true,
                },
                {
                    field: 'patient.full_name',
                    label: 'Paciente',
                    searchable: true,
                },
                {
                    field: 'patient.birthday',
                    label: 'Fecha de Nacimiento',
                    searchable: true,
                },
                {
                    field: 'container.name',
                    label: 'Contenedor',
                    searchable: true
                },
                {
                    field: 'bio_material.name',
                    label: 'Material Biológico',
                    searchable: true
                }
            ],
            apiUrl: 'lots'
        }
    },
    mounted() {
        this.prepareComponent();
    },
    methods: {
        prepareComponent(){
            this.getLotData();
        },
        getLotData(){
            this.loading = true;
            this.getData(this.id).then(response => {
                console.log(response);
                this.data = response.data;
            }).catch(errors => {
                console.error(errors);
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
